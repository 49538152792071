<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
import Editor from '@tinymce/tinymce-vue'
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Digitalisasi SPK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    editor: Editor
  },
  data() {
    return {
      title: "Digitalisasi SPK",
      items: [
      {
          text: "Digitalisasi",
          href: "/",
        },
        {
          text: "Digitalisasi SPK",
          href: "/digitaliasi/spk",
        },
        {
          text: "Edit",
          active: true,
        },
      ],

      dataid: this.$route.params.id,
      // Catch Error Axios
      axiosCatchError: null,
      row_file_upload: [],
      // variable Page
      files: [],

      id_session_user: '',
      nomor_spk: '',
      tanggal_spk: '',
      nama_perusahaan: '',
      alamat_perusahaan: '',
      no_telp: '',
      surat_penawaran: '',
      tanggal_penawaran: '',
      jenis_pekerjaan: '',
      ruang_lingkup: '',
      harga: '',
      total_harga_terbilang: '',
      catatan: '',
      jangka_waktu: '',
      cara_pembayaran: '',
      lain_lain: '',
      pic_penerima_kerja: '',
      jabatan_penerima_kerja: '',
      pic_pemberi_kerja: '',
      jabatan_pemberi_kerja: '',
      file_spk: '',
      file_attachment: '',
      row_file_upload_db: 0,
      jaminan_pelaksana_kerja: 'Pelaksana Pekerjaan dengan ini menjamin bahwa jasa yang akan disediakan dan diserahkan kepada BRI adalah dalam keadaan baik, bebas dari kesalahan pembuatan dan cacat tersembunyi dan sesuai dengan spesifikasi atau fungsinya serta apabila mengandung unsur Hak Atas Kekayaan intelektual (HAKI) maka HAKI yang digunakan sepenuhnya terbebas dari segala bentuk pelanggaran hukum dan atau tuntutan apapun dari pihak manapun berkaitan dengan penggunaan HAKI dimaksud.',

      //upload konfigurasi
      max_upload_size: 0,
      label_upload_tipe: '*',
      textlabel_upload_tipe: 'all',
    };
  },
  mounted() {
    this.getSPK();
    this.getKonfigurasiEkstensi();
  },
  methods: {
    getKonfigurasiEkstensi() {
        let self = this;
        axios
        .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/konfigurasi_ekstensi?menu=digitalisasi"
        )
        .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
            var data_ekstensi = response_data.list_data.data[0];
            var ekstensi = JSON.parse(data_ekstensi.value_extension);
            self.label_upload_tipe= ekstensi.map(value => value.label).join(",");
            self.textlabel_upload_tipe= ekstensi.map(value => value.value).join(",");
            self.max_upload_size = data_ekstensi.max_upload_size;
        }
      });
    },
    getSPK() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/digitalisasi/spk?id=" +
          self.dataid
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.list_data.data[0];
          if (response_data.code == 200) {
            self.nomor_spk = response_data_fix.nomor_spk;
            self.tanggal_spk = response_data_fix.tanggal_spk;
            self.nama_perusahaan = response_data_fix.nama_perusahaan;
            self.alamat_perusahaan = response_data_fix.alamat_perusahaan;
            self.no_telp = response_data_fix.telp_facsimile;
            self.surat_penawaran = response_data_fix.surat_penawaran;
            self.tanggal_penawaran = response_data_fix.tanggal_surat_penawaran;
            self.jenis_pekerjaan = response_data_fix.jenis_pekerjaan;
            self.ruang_lingkup = response_data_fix.ruang_lingkup_pekerjaan;
            self.harga = self.numberFormat(response_data_fix.harga);
            self.total_harga_terbilang = response_data_fix.total_harga_terbilang;
            self.catatan = response_data_fix.catatan_imbalan_jasa;
            self.jangka_waktu = response_data_fix.jangka_waktu;
            self.cara_pembayaran = response_data_fix.cara_pembayaran;
            self.lain_lain = response_data_fix.lain_lain;
            self.pic_penerima_kerja = response_data_fix.pic_penerima_kerja;
            self.jabatan_penerima_kerja = response_data_fix.jabatan_penerima_kerja;
            self.pic_pemberi_kerja = response_data_fix.pic_pemberi_kerja;
            self.jabatan_pemberi_kerja = response_data_fix.jabatan_pemberi_kerja;
            self.file_spk = response_data_fix.file_spk;
            self.jaminan_pelaksana_kerja = response_data_fix.jaminan_pelaksana_kerja;

            // ANAK
            if (response_data_fix.file_attachment) {
              var json_response_file_attachment = JSON.parse(response_data_fix.file_attachment);
              let clear_data_json_response_file_attachment = [];
              $.each(json_response_file_attachment, function (indexInArray, valueOfElement) {
                clear_data_json_response_file_attachment.push({
                  'file': valueOfElement.file
                })
              });
              self.row_file_upload = clear_data_json_response_file_attachment;
              self.row_file_upload_db = self.row_file_upload.length;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
    },
    addRowFileUpload() {
      this.row_file_upload.push({
        file: null,
      });
    },

    removeRowFileUpload(key_deleted) {
      this.row_file_upload.splice(key_deleted, 1);
    },

    uploadFile(row) {
      let self = this;
      if ($("#uploadFile" + row + "")[0].files[0]) {
        if ($("#uploadFile" + row + "")[0].files[0].size < self.max_upload_size) {
          $("#uploadLoading"+row+"").html(
              '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();

          data.append("file", $("#uploadFile" + row + "")[0].files[0]);
          data.append("dir", 'spk/' + self.dataid);
          data.append("accepted_file","image/png, image/gif, image/jpeg");

          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#uploadLoading"+row+"").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            // self.file_dokumen_pendukung = dir_name;
            self.row_file_upload[row].file = dir_name;
            // $("#valUploadFile"+row+"").val(dir_name);
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
              icon: "error",
              title: "Oops...",
              html: 'Ukuran file upload melebihi '+this.formatBytes(self.max_upload_size),
            });
        }
      }
    },
    uploadFileSPK() {
      let self = this;
      if ($("#uploadSPK")[0].files[0]) {
        if ($("#uploadSPK")[0].files[0].size < self.max_upload_size) {
          $("#uploadSPKLoading").html(
              '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#uploadSPK")[0].files[0]);
          data.append("dir", 'spk/' + self.dataid);
          data.append("accepted_file", self.label_upload_tipe);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            // var file_name = response.data.file_name;
            urlres += dir_name;
            $("#uploadSPKLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            
            self.file_spk = dir_name;
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
              icon: "error",
              title: "Oops...",
              html: 'Ukuran file upload melebihi '+this.formatBytes(self.max_upload_size),
            });
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id", self.dataid);
      data.append("id_session_user", Cookies.get("session_id"));
      data.append("nomor_spk", self.nomor_spk);
      data.append("tanggal_spk", self.tanggal_spk);
      data.append("nama_perusahaan", self.nama_perusahaan);
      data.append("alamat_perusahaan", self.alamat_perusahaan);
      data.append("no_telp", self.no_telp);
      data.append("surat_penawaran", self.surat_penawaran);
      data.append("tanggal_penawaran", self.tanggal_penawaran);
      data.append("jenis_pekerjaan", self.jenis_pekerjaan);
      data.append("ruang_lingkup", self.ruang_lingkup);
      data.append("harga", self.harga);
      data.append("total_harga_terbilang", self.total_harga_terbilang);
      data.append("catatan", self.catatan);
      data.append("jangka_waktu", self.jangka_waktu);
      data.append("cara_pembayaran", self.cara_pembayaran);
      data.append("lain_lain", self.lain_lain);
      data.append("pic_penerima_kerja", self.pic_penerima_kerja);
      data.append("jabatan_penerima_kerja", self.jabatan_penerima_kerja);
      data.append("pic_pemberi_kerja", self.pic_pemberi_kerja);
      data.append("jabatan_pemberi_kerja", self.jabatan_pemberi_kerja);
      data.append("file_spk", self.file_spk);
      data.append("file_attachment", JSON.stringify(self.row_file_upload));
      data.append("jaminan_pelaksana_kerja", self.jaminan_pelaksana_kerja);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/digitalisasi/spk/update",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman digitalisasi spk segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "spk" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    printHandle() {
      let self = this;
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL + "/digitalisasi/spk/print?i=" + self.dataid;
      link.setAttribute('target', '_blank');
      link.click();
    },
    downloadSPKHandle() {
      let self = this;
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL + "/digitalisasi/spk/pdf?i=" + self.dataid;
      link.setAttribute('target', '_blank');
      link.click();
    },
    numberFormat(bilangan) {
      if (parseFloat(bilangan) === 0) {
        Swal.fire({
          icon: "warning",
          title: "Peringatan",
          text: "Input tidak boleh 0 pertama kali",
        });
        return bilangan = null;
      }
      var number_string = bilangan.replace(/[^,\d]/g, '').toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }

      return rupiah;
    },

    downloadHandle() {
      let self = this;
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += self.file_spk;
      link.href = urlres;
      link.click();
    },

    downloadEachAttachment(url) {
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += url;
      link.href = urlres;
      link.click();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12 text-end">
        <button @click="printHandle" class="btn btn-block btn-info m-1"><i class="fas fa-print"></i> Print
          SPK</button>
        <button @click="downloadSPKHandle" class="btn btn-block btn-success m-1"><i class="fas fa-download"></i> Download
          SPK</button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr class="bg-light text-center">
                        <th colspan="4">SURAT PERINTAH KERJA(SPK)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>NOMOR SPK <span class="text-danger"> *</span></td>
                        <td>
                          <input type="text" v-model="nomor_spk" maxlength="25" class="form-control" @keydown="nameKeydown($event)">
                        </td>
                        <td>TANGGAL SPK <span class="text-danger"> *</span></td>
                        <td colspan="4">
                          <b-form-input type="date" v-model="tanggal_spk"></b-form-input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr class="bg-light text-center">
                        <th colspan="4">PENERIMA TUGAS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="width: 10px">NAMA PERUSAHAAN</td>
                        <td colspan="4">
                          <b-form-input type="text" v-model="nama_perusahaan"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 10px">ALAMAT PERUSAHAAN</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="alamat_perusahaan"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 10px">N0. TELP/FACSIMILE</td>
                        <td colspan="3">
                          <input type="text" v-model="no_telp" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                        </td>
                      </tr>
                      <tr>
                        <td>SURAT PENAWARAN <span class="text-danger"> *</span></td>
                        <td>
                          <b-form-input type="text" v-model="surat_penawaran"></b-form-input>
                        </td>
                        <td>TANGGAL <span class="text-danger"> *</span></td>
                        <td>
                          <b-form-input type="date" v-model="tanggal_penawaran"></b-form-input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr class="bg-light text-center">
                        <th colspan="4">RUANG LINGKUP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="width: 10px">JENIS PEKERJAAN</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="jenis_pekerjaan"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 10px">RUANG LINGKUP</td>
                        <td colspan="4">
                          <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="ruang_lingkup" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr class="bg-light text-center">
                        <th colspan="4">IMBALAN JASA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="width: 10px">
                          HARGA
                          <small>(termasuk agency fee, PPN 11% dan pajak
                            lainnya)</small>
                        </td>
                        <td colspan="3">
                          <input type="text" class="form-control" v-model="harga"
                            v-on:input="harga = numberFormat(harga)" maxlength="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL HARGA TERBILANG</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="total_harga_terbilang"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td>CATATAN</td>
                        <td colspan="4">
                          <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="catatan" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr class="bg-light text-center">
                        <th colspan="4">KETERANGAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="width: 10px">JANGKA WAKTU & DELIVERABLES</td>
                        <td colspan="4">
                          <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="jangka_waktu" />
                        </td>
                      </tr>
                      <tr>
                        <td>CARA PEMBAYARAN</td>
                        <td colspan="4">
                          <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="cara_pembayaran" />
                        </td>
                      </tr>
                      <tr>
                        <td>JAMINAN DARI PELAKSANA PEKERJAAN</td>
                        <td colspan="4">
                          <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="jaminan_pelaksana_kerja" />
                        </td>
                      </tr>
                      <tr>
                        <td>LAIN - LAIN</td>
                        <td colspan="4">
                          <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="lain_lain" />
                        </td>
                      </tr>
                      <tr>
                        <td>PIC PENERIMA KERJA</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="pic_penerima_kerja"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td>JABATAN PENERIMA KERJA</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="jabatan_penerima_kerja"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td>PIC PEMBERI KERJA</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="pic_pemberi_kerja"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td>JABATAN PEMBERI KERJA</td>
                        <td colspan="3">
                          <b-form-input type="text" v-model="jabatan_pemberi_kerja"></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td>FILE SPK <br><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal Upload: {{ formatBytes(max_upload_size) }})</small></td>
                        <td colspan="3">
                          <input type="file" :accept="label_upload_tipe" class="form-control" id="uploadSPK" @change="uploadFileSPK()">
                          <input type="hidden" v-model="file_spk">
                          <div class="respond-input-file float-left" id="uploadSPKLoading">
                            <div v-if="file_spk" class="btn btn-info btn-sm mt-1" @click="downloadHandle()">
                              <i class="fa fa-download"></i> Download
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>FILE ATTACHMENT <br><small style="color: red">(images, Ukuran Maksimal Upload: {{ formatBytes(max_upload_size) }})</small></td>
                        <td colspan="3">
                          <table class="table table-bordered table-condensed table-custom" style="width: 100%">
                            <thead>
                              <tr class="text-center">
                                <th style="width: 90%">File</th>
                                <th style="width: 10%">
                                  <button type="button" class="btn btn-sm btn-primary" v-on:click="addRowFileUpload()">
                                    <i class="fa fa-plus"></i>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(
                                    row_file_upload_value, row_file_upload_key
                                  ) in row_file_upload" :key="row_file_upload_key">
                                <td>
                                  <input type="file" class="form-control" accept="image/png, image/gif, image/jpeg" :id="'uploadFile' + row_file_upload_key"
                                    @change="uploadFile(row_file_upload_key)">
                                  <input type="hidden" v-model="row_file_upload_value.file">
                                  <div class="respond-input-file float-left" :id="'uploadLoading' + row_file_upload_key">
                                  </div>
                                  <div v-if="row_file_upload_db >= row_file_upload_key + 1 && row_file_upload_db != 0">
                                    <div class="respond-input-file float-left" :id="'uploadLoading' + row_file_upload_key">
                                      <div class="btn btn-info btn-sm mt-1"
                                        @click="downloadEachAttachment(row_file_upload_value.file)">
                                        <i class="fa fa-download"></i> Download
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div class="respond-input-file float-left" :id="'uploadLoading' + row_file_upload_key">
                                    </div>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <div class="btn btn-danger btn-sm" v-on:click="
                                    removeRowFileUpload(row_file_upload_key)
                                  ">
                                    <i class="bx bx-minus"></i>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <router-link :to="{ name: 'sponsorship' }" class="btn bg-secondary text-white">
                    <i class="fa fa-chevron-left"></i> Kembali Ke List Spk
                  </router-link>
                </div>
                <div class="col-md-6 text-end">
                  <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> SIMPAN</b-button>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
